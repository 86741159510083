import specs from '../specs';

export default function (lhr) {
	// ["largest-contentful-paint-element"].details.items[].node.boundingRect.<width|height>
	const result = {
		testName: 'Dimensions',
		testResult: 'Error: format not recognized',
		spec: `Allowed: ${specs.dimensions.map(format => format.id).join(', ')}`,
		testId: "format",
		label: "",
		valid: false,
		details: [],
	};
	const details = lhr.audits["dom-size"].details;
	if (details && details.items && details.items.length) {
		let outerBodyDimensions = null;
		const items = details.items.filter(item => typeof item.node !== 'undefined');
		const format = items.reduce((prev, currItem) => {
			let result = prev;
			const node = currItem.node;
			const { width, height } = node.boundingRect;
			if (node.selector === 'body') {
				outerBodyDimensions = { width, height };
				result = prev;
			} else {
				result = {
					width: Math.max(width, prev.width),
					height: Math.max(height, prev.height)
				};
			}
			return result;
		}, { width: 0, height: 0 });
		if (format.width === 0 && format.height === 0) {
			format.width = outerBodyDimensions.width;
			format.height = outerBodyDimensions.height;
		}
		result.valid = isFormatValid(format);
		result.testResult = makeFormatString(format);
		result.spec = result.valid ? '' : result.spec;
	}
	return result;

	function isFormatValid(format) {
		let result = false;
		const formatId = makeFormatString(format);
		const matchingFormats = specs.dimensions.filter(format => format.id === formatId);
		result = matchingFormats.length > 0;
		return result;
	}

	function makeFormatString(format) {
		let result = 'n/a';
		const { width, height } = format;
		if (width !== null || height !== null) {
			result = `${width}x${height}`;
		}
		return result;
	}
}
