import specs from '../specs';
import { displayValue } from './base';

export default function (lhr) {
    const value = lhr.custom.initialLoad.size;
    const max = specs.initial_load.max;
    const result = {
        testName: 'Initial Load',
        testResult: `${displayValue(value / 1000)} kb`,
        spec: `Max ${max / 1000} kb`,
        testId: "initial_load",
        label: "",
        valid: value <= max,
        details: [],
    };
    return result;
}
