import specs from '../specs';

export default function (lhr) {
	const totalTiming = lhr.timing.total;
	const bootUpTimeItems = lhr.audits['bootup-time'].details.items;
	const mainThreadValue = lhr.audits['mainthread-work-breakdown'].numericValue;
	const peakItem = bootUpTimeItems.sort((a, b) => (a.total < b.total ? 1 : -1))[0];
	const peakValue = parseFloat(peakItem.total) / totalTiming;
	const averageValue = mainThreadValue / bootUpTimeItems.length / totalTiming;
	const { peak, average } = specs.cpu_usage;
	const maxValueTexts = [
		`${parseInt(average * 100)}% average`,
		`${parseInt(peak * 100)}% peak`
	];
	const valueTexts = [
		`${parseInt(averageValue * 100)}% average`,
		`${parseInt(peakValue * 100)}% peak`
	]
	const result = {
		testName: 'CPU usage',
		testResult: valueTexts.join(', '),
		spec: `Max ${maxValueTexts.join(', ')}`,
		testId: "cpu_usage",
		valid: peakValue <= peak && averageValue <= average,
		details: [],
	};
	return result;
}
