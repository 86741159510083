import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03cab1a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    to: $props.to,
    class: _normalizeClass(["link", { active: $setup.isActive }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("i", {
        class: _normalizeClass(["icon", $props.icon])
      }, null, 2),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (!$setup.collapsed)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["to", "class"]))
}