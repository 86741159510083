import { createRouter, createWebHistory } from 'vue-router';
import ReportsPage from "../views/ReportsPage";
import SettingsPage from "../views/SettingsPage";

import { useAuthenticator } from "@aws-amplify/ui-vue";
// eslint-disable-next-line no-unused-vars
const auth = useAuthenticator();


const routes = [
  {
    path: '/',
    name: 'ReportsPage',
    component: ReportsPage
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: SettingsPage
  },
  {
    path: '/signout',
    name: 'Sign Out',
    beforeEnter: (to, from) => {
      auth.signOut();
      return from;
    },
    component: {
      template: ''
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
