import specs from '../specs';
import { getDiagnosticValue, displayValue } from './base';

export default function (lhr) {
	const value = getDiagnosticValue(lhr, 'totalByteWeight');
	const max = specs.subload.max;
	const result = {
		testName: 'Subload',
		testResult: `${displayValue(value / 1000)} kb`,
		spec: `Max ${max / 1000} kb`,
		testId: "subload",
		label: "",
		valid: value <= max,
		details: [],
	};
	return result;
}
