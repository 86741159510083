import specs from '../specs';

export default function (lhr) {
	const max = specs.time_to_visual_start.max;
	const result = {
		testName: 'Time to visual start',
		testResult: 'n/a',
		spec: `Max ${max} seconds`,
		testId: "time_to_visual_start",
		valid: false,
		details: [],
	};
	// NOTE: consider "max-potential-fid"
	const firstContentfulPaint = lhr.audits["first-contentful-paint"];
	const numericValue = firstContentfulPaint.numericValue;
	if (typeof numericValue === 'number') {
		result.valid = (numericValue / 1000) <= max;
		result.testResult = firstContentfulPaint.displayValue;
	}
	return result;
}
