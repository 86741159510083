export default function (lhr) {
	const result = {
		testName: "HTTPS",
		spec: "",
		testId: "https",
		label: "",
		valid: false,
		testResult: "n/a",
		details: [],
	};
	if (lhr) {
		if (lhr.audits) {
			const items = lhr.audits["network-requests"].details.items;
			if (items.length == 0) {
				return result;
			}
			const reducer = (prev, curr) => {
				const result = prev && isHttps(curr);
				return result;
			};
			if (items.reduce(reducer, true)) {
				result.testResult = "ok";
				result.valid = true;
			} else {
				result.testResult = "fail";
			}
		}
	}
	return result;

	function isHttps(item) {
		const code = item.statusCode;
		const wasSuccessfull = code >= 200 && code < 400;
		const isHttpUrl = item.url.indexOf("https://") > -1;
		return isHttpUrl && wasSuccessfull;
	}

}