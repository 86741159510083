import { createApp } from 'vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import App from './App.vue'
import router from './router/index';
import AmplifyVue from '@aws-amplify/ui-vue';

import '@fortawesome/fontawesome-free/js/all';


Amplify.configure(aws_exports);

const app = createApp(App);
app.use(AmplifyVue);
app.use(router);
app.mount('#app');


