import specs from '../specs';
import { getDiagnosticValue, getDiagnosticValidation } from './base';

export default function (lhr) {
	const resultValue = getDiagnosticValue(lhr, 'numRequests');
	const max = specs.initial_file_request.max;
	const result = getDiagnosticValidation({
		testName: 'Initial file requests',
		resultValue,
		spec: `Max ${max} requests`,
		valid: resultValue <= max,
		resultUnit: 'requests'
	});
	return result;
}
