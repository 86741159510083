import subload from './validations/subload';
import fileRequest from './validations/fileRequest';
import dimensions from './validations/dimensions';
import timeToVisualStart from './validations/timeToVisualStart';
import https from './validations/https';
import cpuUsage from './validations/cpuUsage';
import initialLoad from './validations/initialLoad';

export default function (lhr) {
	const result = [];
	result.push(https(lhr));
	result.push(initialLoad(lhr));
	result.push(subload(lhr));
	result.push(fileRequest(lhr));
	result.push(dimensions(lhr));
	result.push(timeToVisualStart(lhr));
	result.push(cpuUsage(lhr));
	return result;
}
