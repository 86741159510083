export default {
	subload: {
		max: 2000000
	},
	initial_load: {
		max: 1500000
	},
	initial_file_request: {
		max: 25
	},
	dimensions: [
		{
			width: 300,
			height: 250,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 468,
			height: 400,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 160,
			height: 600,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 250,
			height: 770,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 300,
			height: 600,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 994,
			height: 250,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 944,
			height: 118,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 245,
			height: 770,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 960,
			height: 800,
			get id() {
				return `${this.width}x${this.height}`
			}
		},
		{
			width: 320,
			height: 416,
			get id() {
				return `${this.width}x${this.height}`
			}
		}
	],
	time_to_visual_start: {
		max: 0.8
	},
	cpu_usage: {
		peak: .6,
		average: .3
	}
}
