function getDiagnosticValue(lhr, diagnosticName) {
	return lhr.audits.diagnostics.details.items.reduce((prev, item) => prev + item[diagnosticName], 0);
}

function displayValue(value) {
	return value.toFixed(2);
}

function getDiagnosticValidation(params) {
	const { testName, resultValue, resultUnit, spec, valid } = params;
	const testId = testName.toLowerCase().split(' ').join('_');
	return {
		testName, spec, valid, testId,
		testResult: `${resultValue} ${resultUnit}`,
	}
}

export {
	getDiagnosticValue,
	displayValue,
	getDiagnosticValidation
}
