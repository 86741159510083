import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e273dc3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reportList" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ReportItem = _resolveComponent("ReportItem")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.creativeData, (creativeDataItem) => {
      return (_openBlock(), _createBlock(_component_ReportItem, {
        key: creativeDataItem.uri,
        creativeData: creativeDataItem
      }, null, 8, ["creativeData"]))
    }), 128))
  ]))
}